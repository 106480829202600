import { React, useRef } from "react";

const PreviewBox = () => {
  const audioRef = useRef(null);
  return (
    <div className="avatar-preview">
      <video id="avatar-video-element" autoPlay playsInline>
        <track default kind="captions" srcLang="en" src="SUBTITLE_PATH" />
      </video>
      <audio
        id="avatar-audio-element"
        ref={audioRef}
        autoPlay
        onEnded={() => console.log("Audio playback completed")}
      >
        <track default kind="captions" srcLang="en" src="SUBTITLE_PATH" />
      </audio>
    </div>
  );
};

export default PreviewBox;
