import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALERT_TYPES, CHAT_ROUTE, PROJECT_CHAT_ROUTE } from '../../../../../constants';
import { faCommentAlt, faEdit, faTrashAlt, faFileLines, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faEllipsis, faSpinner, faPrint } from '@fortawesome/free-solid-svg-icons';
import { deleteChatRequest, removeTextAnimation, updateChatRequest } from '../../../../../redux/slicers/chat';
import { useDispatch } from 'react-redux';
import useGetChatMessageHistory from '../../../../../hooks/useGetChatMessageHistory';
import useGetChatsList from '../../../../../hooks/useGetChatsList';
import clsx from 'clsx';
import { CustomDispatch } from '../../../../../helpers';
import { ConfirmationModal, Loader } from '../../../../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactTyped } from 'react-typed';
import { Dropdown, Input } from 'antd';
import ShareAllIcon from '../../../../../components/icons/ShareAllIcon';
import { makeTextToShareFromMessages, toastAlert } from '../../../../../utils';
import './styles.scss';

const ChatSidebar = ({ preview, isProjectApp, appConfig, projectId }) => {
  // STATES
  const [deleteChatPreview, setDeleteChatPreview] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [isAllShared, setIsAllShared] = useState(false);
  const [isAllCopied, setIsAllCopied] = useState(false);
  const [sharingInProgress, setSharingInProgress] = useState(false);
  const [copyingInProgress, setCopyingInProgress] = useState(false);
  const [printingInProgress, setPrintingInProgress] = useState(false);
  const [isInPrint, setIsInPrint] = useState(null);

  // CUSTOM DISPATCH
  const [deleteChat, deleteLoader] = CustomDispatch(deleteChatRequest);
  const [updateChat] = CustomDispatch(updateChatRequest);

  // REDUX DATA
  const localProjectId = isProjectApp ? projectId : appConfig.id;
  const { data: currentChatListData, isComplete: currentChatListIsComplete } = useGetChatsList(localProjectId);
  const { data: sharedChatData, isComplete: sharedChatComplete } = useGetChatMessageHistory(localProjectId, isAllShared);
  const { data: copiedChatData, isComplete: copiedChatComplete } = useGetChatMessageHistory(localProjectId, isAllCopied);

  const chatCategories = Array.isArray(currentChatListData) ? [...new Set(currentChatListData.map((chat) => chat.category))] : [];

  // CONST VALS
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentChatId = searchParams.get('chat');

  // CONST VALS
  const dispatch = useDispatch();
  const editInputRef = useRef(null);

  // HELPERS

  // HANDLERS
  const removeAnimationHandler = (id) => {
    dispatch(removeTextAnimation(id));
  };

  const editChatPreviewHandler = (e, index, item) => {
    e.stopPropagation();
    setSelectedChatId(item.id);
    setEditInputValue(item.name);
    setEditInputIndex(item.id);
  };

  const updateChatHandler = () => {
    const oldVal = currentChatListData.find((x) => x.id === editInputIndex).name;
    setEditInputIndex(-1);
    if (editInputValue === oldVal || editInputValue === '') return;
    const payload = {
      method: 'update',
      details: {
        app_id: isProjectApp ? projectId : appConfig.id,
        session_id: selectedChatId,
        session_name: editInputValue
      }
    };
    updateChat({ payload });
    setEditInputValue('');
    setSelectedChatId(null);
  };

  const deleteChatPreviewHandler = (e, id) => {
    e.stopPropagation();
    setSelectedChatId(id);
    setDeleteChatPreview(true);
  };

  const deleteChatHandler = () => {
    const payload = {
      method: 'delete',
      details: {
        app_id: isProjectApp ? projectId : appConfig.id,
        session_id: selectedChatId
      }
    };
    deleteChat({
      payload,
      success() {
        setDeleteChatPreview(false);
        if (currentChatId !== selectedChatId) return;
        const projectRoute = PROJECT_CHAT_ROUTE.replace(':projectslug', projectId);
        const route = isProjectApp ? projectRoute : CHAT_ROUTE;
        navigate(route);
      }
    });
  };

  const shareText = useCallback(async () => {
    try {
      setSharingInProgress(true);
      const textToShare = getTextToShare(sharedChatData);
      if (navigator?.share) {
        const sharedData = { title: '', text: textToShare };
        await navigator.share(sharedData);
      } else {
        toastAlert('Your browser does not support sharing.', ALERT_TYPES.ERROR);
      }
    } catch (e) {
      console.error(`Error while sharing.`, e);
    } finally {
      setTimeout(() => {
        setIsAllShared(undefined);
        setSharingInProgress(false);
      }, 2000);
    }
  }, [sharedChatData]);

  const copyText = useCallback(async () => {
    try {
      setCopyingInProgress(true);
      const textToShare = getTextToShare(copiedChatData);
      await navigator.clipboard.writeText(textToShare);
    } catch (error) {
      console.error(`Error while copying.`, error);
    } finally {
      setTimeout(() => {
        setIsAllCopied(undefined);
        setCopyingInProgress(false);
      }, 2000);
    }
  }, [copiedChatData]);

  const printText = useCallback(async () => {
    if (window) window.print();
    setPrintingInProgress(false);
    setIsInPrint({ chatId: currentChatId, status: false });
  }, [currentChatId]);

  const handleShareAll = useCallback((chatId) => {
    setIsAllShared(chatId);
  }, []);

  const handleCopyAll = useCallback((chatId) => {
    setIsAllCopied(chatId);
  }, []);

  const handlePrintAll = useCallback(
    (chatId) => {
      if (chatId !== currentChatId) {
        setSearchParams({ chat: chatId, print: true });
      } else {
        printText();
      }
    },
    [currentChatId, printText, setSearchParams]
  );

  const makeItemDropdownMenu = useMemo(
    () =>
      ({ item, itemIndex, shareDataLoading, isEdit }) => {
        return [
          {
            key: '1',
            label: (
              <button
                className="chat-sidebar-menu-button"
                onClick={(e) => editChatPreviewHandler(e, itemIndex, item)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    editChatPreviewHandler(e, itemIndex, item);
                  }
                }}
                tabIndex={0}
              >
                Edit
              </button>
            ),
            icon: <FontAwesomeIcon onClick={(e) => editChatPreviewHandler(e, itemIndex, item)} icon={faEdit} />,
            disabled: isEdit
          },
          {
            key: '5',
            label: (
              <button
                className="chat-sidebar-menu-button"
                onClick={() => handleCopyAll(item.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleCopyAll(item.id);
                  }
                }}
                tabIndex={0}
              >
                Copy Chat
              </button>
            ),
            icon: <FontAwesomeIcon icon={faFileLines} onCopy={() => handleCopyAll(item.id)} />,
            disabled: shareDataLoading
          },
          {
            key: '6',
            label: (
              <button
                className="chat-sidebar-menu-button"
                onClick={() => handleShareAll(item.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleShareAll(item.id);
                  }
                }}
                tabIndex={0}
              >
                Share Chat
              </button>
            ),
            icon: <ShareAllIcon className="shareall-icon" onClick={() => handleShareAll(item.id)} />,
            disabled: shareDataLoading
          },
          {
            key: '7',
            label: (
              <button
                className="chat-sidebar-menu-button"
                onClick={() => handlePrintAll(item.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handlePrintAll(item.id);
                  }
                }}
                tabIndex={0}
              >
                Print Chat
              </button>
            ),
            icon: <FontAwesomeIcon onClick={() => handleShareAll(item.id)} icon={faPrint} />,
            disabled: shareDataLoading
          },
          {
            key: '99',
            label: (
              <button
                className="chat-sidebar-menu-button"
                onClick={(e) => deleteChatPreviewHandler(e, item.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    deleteChatPreviewHandler(e, item.id);
                  }
                }}
                tabIndex={0}
              >
                Delete
              </button>
            ),
            icon: <FontAwesomeIcon onClick={(e) => deleteChatPreviewHandler(e, item.id)} icon={faTrashAlt} />,
            disabled: isEdit,
            danger: true
          }
        ];
      },
    [handleCopyAll, handlePrintAll, handleShareAll]
  );

  const getTextToShare = (sharedChatData = []) => {
    // Work out the text to share
    if (!Array.isArray(sharedChatData) || sharedChatData.length === 0) {
      return '';
    }
    const textToShare = sharedChatData
      .map((message) => {
        if (message.id.endsWith('query')) {
          const searchForId = message.id.replace(/-query/, '');
          const messageResponse = sharedChatData.find((response) => response.id === searchForId);
          if (!messageResponse) {
            return makeTextToShareFromMessages(message.message, '');
          }
          return makeTextToShareFromMessages(message.message, messageResponse.message);
        }
        return undefined;
      })
      .filter((n) => n)
      .join('\n\n');
    return textToShare;
  };

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputIndex]);

  useEffect(() => {
    if (isAllShared && sharedChatComplete && !sharingInProgress) {
      shareText();
    }
  }, [isAllShared, shareText, sharedChatComplete, sharingInProgress]);
  useEffect(() => {
    if (isAllCopied && copiedChatComplete && !copyingInProgress) {
      copyText();
    }
  }, [copiedChatComplete, copyText, copyingInProgress, isAllCopied]);

  // If the page changes then reset the print flag
  useEffect(() => {
    if (!isInPrint || isInPrint.chatId !== currentChatId) {
      setIsInPrint({ chatId: currentChatId, status: !!searchParams.get('print') });
    }
  }, [currentChatId, isInPrint, searchParams]);

  // Need to delay printing until at least some data is visible.
  useEffect(() => {
    let interval;
    const doCheckMessagesDone = () => {
      const displayedElements = document.querySelectorAll('.chat-box-messages-loader');
      if (isInPrint && isInPrint.chatId === currentChatId && isInPrint.status && !printingInProgress && displayedElements.length === 0) {
        setPrintingInProgress(true);
        setIsInPrint({ chatId: currentChatId, status: false });
        clearInterval(interval);
        interval = undefined;
        printText();
      }
    };
    if (!interval && isInPrint && isInPrint.chatId === currentChatId && isInPrint.status && !printingInProgress) {
      interval = setInterval(doCheckMessagesDone, 500);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentChatId, currentChatListData, isInPrint, printText, printingInProgress, searchParams]);

  return (
    <div className={clsx('chat-sidebar', preview && 'active')}>
      {!currentChatListIsComplete ? (
        <Loader height="100%" />
      ) : (
        <div className="chats-listing customize-form">
          {chatCategories.map((chat, index) => {
            const catChats = currentChatListData?.filter((item) => item.category === chat);
            return (
              <React.Fragment key={index}>
                <button className="chat-title">{chat}</button>
                {catChats.map((item, i) => {
                  const itemIndex = `${index}-${i}`;
                  const isEdit = editInputIndex === item.id;
                  const shareDataLoading =
                    (isAllCopied?.chatId === item.id && isAllCopied?.loading) || (isAllShared?.chatId === item.id && isAllShared?.loading);
                  const shareDataComplete =
                    (isAllCopied?.chatId === item.id && !isAllCopied?.loading) || (isAllShared?.chatId === item.id && !isAllShared?.loading);

                  const menuItems = makeItemDropdownMenu({
                    item,
                    itemIndex,
                    shareDataLoading,
                    isEdit
                  });

                  return (
                    <button
                      key={itemIndex}
                      onClick={() => !isEdit && setSearchParams({ chat: item.id })}
                      className={clsx(`chat-item`, currentChatId === item.id && 'active')}
                    >
                      <FontAwesomeIcon className="thumb" icon={faCommentAlt} />
                      <span className="title">
                        {isEdit ? (
                          <Input
                            ref={editInputRef}
                            key={itemIndex}
                            size="small"
                            value={editInputValue}
                            onChange={(e) => setEditInputValue(e.target.value)}
                            onBlur={updateChatHandler}
                            onPressEnter={updateChatHandler}
                          />
                        ) : (
                          <>
                            {item.isAnimated ? (
                              <ReactTyped
                                strings={[item.name]}
                                typeSpeed={25}
                                onComplete={() => removeAnimationHandler(item.id)}
                                showCursor={false}
                              />
                            ) : (
                              item.name
                            )}
                          </>
                        )}
                      </span>
                      {shareDataLoading || shareDataComplete ? (
                        <FontAwesomeIcon icon={shareDataLoading ? faSpinner : faCircleCheck} spin={!!shareDataLoading} className="spinner-btn" />
                      ) : (
                        <div className="action-box">
                          <Dropdown
                            menu={{
                              items: menuItems,
                              onClick: (e) => e.domEvent.stopPropagation()
                            }}
                            trigger="click"
                            overlayClassName="chat-sidebar-menu-dropdown"
                          >
                            <FontAwesomeIcon onClick={(e) => e.stopPropagation()} className="ellipsis-btn" icon={faEllipsis} />
                          </Dropdown>
                        </div>
                      )}
                    </button>
                  );
                })}
              </React.Fragment>
            );
          })}{' '}
          {!currentChatListIsComplete && <Loader height="30px" size={10} style={{ marginTop: '10px' }} />}
        </div>
      )}
      <ConfirmationModal
        preview={deleteChatPreview}
        isLoading={deleteLoader}
        title="Delete Chat"
        description="Are you sure you want to delete this chat?"
        confirmBtnText="Delete"
        confirmBtnHandler={deleteChatHandler}
        previewHandler={() => setDeleteChatPreview(false)}
      />
    </div>
  );
};

export default ChatSidebar;
