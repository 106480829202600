import { useState, useEffect } from 'react';
import { CustomDispatch } from '../helpers';
import { getChatsRequest } from '../redux/slicers/chat';
import { manipulateChatList } from '../data-manipulator/chat';

const useGetChatsList = (projectId) => {
  const [currentDataKey, setCurrentDataKey] = useState(undefined);
  const [isComplete, setIsComplete] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [downloadedData, setDownloadedData] = useState(undefined);
  const [getChats] = CustomDispatch(getChatsRequest);

  const dataKey = `${projectId}`;

  // if the user changed params passed in then handle the change.
  if (currentDataKey && currentDataKey !== dataKey) {
    setCurrentDataKey(dataKey);
    // Clear down possible incomplete data.
    setDownloadedData(undefined);
    setMoreData(false);
    setIsComplete(false);
  }

  useEffect(() => {
    // Handle if no chats have been requested.
    if (!projectId) return;

    // If we have downloaded data and nothing more to retrieve then we're done
    if (downloadedData && !moreData) return;

    const payload = {
      method: 'list',
      details: {
        app_id: projectId,
        archive: false
      },
      item_limit: 50
    };
    if (moreData) payload['next_page'] = moreData;

    getChats({
      payload,
      success: (data) => {
        // We need to manipulate the data ourselves to add the missing data.
        const manuipulatedData = manipulateChatList(data?.chat_sessions ?? []);
        // As that has category data
        setMoreData(data?.next_page);
        if (!data?.next_page) {
          setIsComplete(true);
        }
        if (downloadedData) {
          setDownloadedData((prev) => [...(prev ?? []), ...manuipulatedData]);
        } else {
          setDownloadedData([...manuipulatedData]);
        }
      }
    });
  }, [
    dataKey,
    downloadedData,
    getChats,
    moreData,
    projectId,
    setDownloadedData
  ]);

  return { isComplete, isInProgress: !!moreData, data: downloadedData };
};

export default useGetChatsList;
