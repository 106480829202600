import { isEmptyValue } from "../utils";

export const manipulateProjectDetailData = (data) => {
  let project = {
    enable_upload: data?.interface?.enable_upload ?? false,
    enable_voice: data?.interface?.enable_voice ?? false,
    webTitle: data?.interface?.title ?? "",
    publicDescription: data?.interface?.description ?? "",
    botName: isEmptyValue(data?.interface?.bot_name, "Bot"),
    desclaimerMessage: isEmptyValue(data?.interface?.disclaimer, `${data?.interface?.title} bot may display incorrect or false information.`),
    inputBoxPlaceholder: isEmptyValue(data?.interface?.input_placeholder, "Type your prompt here"),
    enableAvatar: data?.interface?.avatar?.enable_avatar ?? false,
    avatarVisibility: data?.interface?.avatar?.avatar_visibility ?? false,
    openAiApiKey: data?.interface?.avatar?.openai_api_key?.api_key ?? process.env.REACT_APP_OPENAI_API_KEY,
    systemPrompt: data?.interface?.avatar?.system_prompt,
    silenceThreshold: data?.interface?.avatar?.silenceThreshold ?? 1000,
    loudnessThreshold: data?.interface?.avatar?.loudnessThreshold ?? 15,
    silenceDetectionThreshold: data?.interface?.avatar?.silenceDetectionThreshold ?? 10,
    avatarApiKey: data?.interface?.avatar?.api_key ?? "",
    generateButtonText: isEmptyValue(data?.interface?.generate_button_text, "Generate"),
    splashText: isEmptyValue(data?.interface?.splash_text, "Ask ASU GPT any questions you may have about ASU!"),
    isLocked: data?.public_interface ? false : true,
    starterGroups: data?.interface?.starter_groups ?? [],
    enableStarters: data?.interface?.starter_groups?.length >= 1 ?? false,
  };
  return project;
};
