import ErrorThumbnail from "../assets/images/error-thumb.svg";
import Logo from "../assets/images/logo.png";
import LogoText from "../assets/images/logo-text.png";
import LogoThumb from "../assets/images/logo-thumb.png";
import AiLoader from "../assets/images/ai-loader.gif";
import AccessThumbnail from "../assets/images/access-thumb.png";
import VoiceLoader from "../assets/images/voice-loader.gif";
import SuggestionThumb from "../assets/images/suggestion-thumb.svg";
import SuggestionIcon from "../assets/images/suggestion-icon.svg";

const Images = {
  ErrorThumbnail,
  Logo,
  LogoText,
  LogoThumb,
  AiLoader,
  AccessThumbnail,
  VoiceLoader,
  SuggestionThumb,
  SuggestionIcon,
};

export default Images;
