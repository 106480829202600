import { useState, useEffect } from 'react';
import { CustomDispatch } from '../helpers';
import { getMessagesRequest } from '../redux/slicers/chat';

const useGetChatMessageHistory = (projectId, chatId) => {
  const [currentDataKey, setCurrentDataKey] = useState(undefined);
  const [isComplete, setIsComplete] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [downloadedData, setDownloadedData] = useState(undefined);
  const [getMessages] = CustomDispatch(getMessagesRequest);

  const dataKey = `${projectId}-${chatId}`;

  // if the user changed chatIds passed in then handle the change.
  if (!currentDataKey) {
    setCurrentDataKey(dataKey);
  } else if (currentDataKey !== dataKey) {
    setCurrentDataKey(dataKey);
    // Clear down possible incomplete data.
    setDownloadedData(undefined);
    setMoreData(false);
    setIsComplete(false);
  }

  useEffect(() => {
    // Handle if no chats have been requested.
    if (!projectId || !chatId) return;

    // If we have downloaded data and nothing more to retrieve then we're done
    if (downloadedData && !moreData) return;

    const payload = {
      method: 'describe',
      details: {
        app_id: projectId,
        session_id: chatId
      },
      item_limit: 50,
      storeData: false
    };
    if (moreData) payload['next_page'] = moreData;
    getMessages({
      payload,
      success: (data) => {
        // Store the chat history
        if (data?.chat_session?.chat_history) {
          if (downloadedData) {
            setDownloadedData((prev) => [
              ...(prev ?? []),
              ...(data?.chat_session?.chat_history ?? [])
            ]);
          } else {
            setDownloadedData([...(data?.chat_session?.chat_history ?? [])]);
          }
          // Remember if we need to download more messages.
          setMoreData(data?.next_page);
          if (!data?.next_page) {
            setIsComplete(true);
          }
        } else {
          if (downloadedData) {
            setDownloadedData((prev) => [
              ...(prev ?? []),
              ...(data?.messages?.messages ?? [])
            ]);
          } else {
            setDownloadedData([...(data?.messages?.messages ?? [])]);
          }
          // Remember if we need to download more messages.
          setMoreData(data.isMoreData);
          if (!data.isMoreData) {
            setIsComplete(true);
          }
        }
        // No more data - we're complete.
        if (!data?.next_page) {
          setIsComplete(true);
        }
      },
      error: () => {}
    });
  }, [chatId, dataKey, downloadedData, getMessages, moreData, projectId]);

  return { isComplete, isInProgress: !!moreData, data: downloadedData };
};

export default useGetChatMessageHistory;
