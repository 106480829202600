import React, { useEffect, useState, useRef } from "react";
import { convertBlobToBase64, convertWebMToWAV, toastAlert } from "../../../../../utils";
import { Input } from "antd";
import { ALERT_TYPES, AVATAR_MODE_MODALITIES, AVATAR_SYSTEM_PROMPT } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faMicrophoneAlt,
  faStop,
  faKeyboard,
  faArrowUp,
  faSpinner,
  faTimes,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { useRealtimeSession } from "./realtime";
import { AnamEvent } from "@anam-ai/js-sdk/dist/module/types";
import { Button, Loader } from "../../../../../components";
import { Images } from "../../../../../theme";
import "./styles.scss";

const ChatBox = ({ previewHandler, isProjectApp, appConfig, anamClientRef, anamStreamRef, initializeAnamClient }) => {
  // STATES
  const [inputPreview, setInputPreview] = useState(false);
  const [message, setMessage] = useState("");
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioPermission, setaudioPermission] = useState(false);
  const [cleanupFunction, setCleanupFunction] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isProcessingPaused, setIsProcessingPaused] = useState(false);
  const [recordingAnalyzer, setRecordingAnalyzer] = useState(null);
  const [voiceRecording, setVoiceRecording] = useState(false);
  const [subTitles, setSubTitles] = useState("");
  const [starterPreview, setStarterPreview] = useState(true);
  const [resettingSession, setResettingSession] = useState(false);
  const [initLoad, setinitLoad] = useState(true);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.projectData);

  // REF TO KEEP TRACK OF AUDIO PLAYER
  const audioLoaderRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const isPaused = useRef(false);
  const recordingBarsRef = useRef([]);
  const ignoreAudioInput = useRef(false);

  // CONST VALS
  const discalimerMessage = appConfig?.desclaimerMessage;
  const isUserSpeaking = audioRecorder?.state !== "inactive";
  const disabledInput = isPlaying || isLoading || message === "";
  const { transcript, resetTranscript, browserSupportsContinuousListening } = useSpeechRecognition();
  const { sendJsonMessage, readyState, reconnect } = useRealtimeSession(projectData?.openAiApiKey, projectData?.systemPrompt, (message) => {
    resetTranscript();
    setLoading(false);
    setPlaying(true);

    // Set flag to ignore audio input while bot is speaking
    ignoreAudioInput.current = true;

    // Stop any ongoing recording when the bot starts speaking
    if (audioRecorder && audioRecorder.state === "recording" && !resettingSession) {
      try {
        audioRecorder.stop();
        SpeechRecognition.stopListening();
        setVoiceRecording(false);
      } catch (err) {}
    }

    if (!resettingSession) {
      if (!anamStreamRef.current || !anamStreamRef.current.isActive()) {
        anamStreamRef.current = anamClientRef.current.createTalkMessageStream();
      }
      anamStreamRef.current.streamMessageChunk(message, message.includes(`<EOS>`));
      setSubTitles((prev) => prev + message);
    }
  });

  // HELPERS
  const sendAudioHelper = (audio) => {
    setStarterPreview(false);
    sendJsonMessage({
      type: "input_audio_buffer.append",
      audio: audio,
    });
    sendJsonMessage({ type: "input_audio_buffer.commit" });
    sendJsonMessage({
      type: "response.create",
      response: {
        modalities: AVATAR_MODE_MODALITIES,
        instructions: projectData?.systemPrompt ?? AVATAR_SYSTEM_PROMPT,
        voice: "alloy",
        output_audio_format: "pcm16",
        temperature: 0.8,
        max_output_tokens: 1024,
      },
    });
    resetTranscript();
    setSubTitles("");
  };

  const sendMessageHelper = (premessage) => {
    setLoading(true);
    setStarterPreview(false);
    sendJsonMessage({
      type: "conversation.item.create",
      item: {
        type: "message",
        role: "user",
        content: [
          {
            type: "input_text",
            text: premessage ?? message,
          },
        ],
      },
    });

    sendJsonMessage({
      type: "response.create",
      response: {
        modalities: AVATAR_MODE_MODALITIES,
        instructions: projectData?.systemPrompt ?? AVATAR_SYSTEM_PROMPT,
        temperature: 0.8,
        max_output_tokens: 1024,
      },
    });
    resetTranscript();
    setMessage("");
    setSubTitles("");
  };

  // HANDLERS
  const closeHandler = () => {
    if (audioRecorder) audioRecorder.stop();
    previewHandler();
  };

  const onAudioEndHandler = () => {
    setPlaying(false);
    setSubTitles("");
    ignoreAudioInput.current = false;
    if (audioRecorder && !isPaused.current) {
      if (audioRecorder.state === "recording") audioRecorder.stop();
      if (audioRecorder.state === "inactive") audioRecorder.start();
      if (audioRecorder.state === "paused") audioRecorder.resume();
    }
  };

  const setupMediaStream = async () => {
    try {
      let silenceTimer;
      const silenceThreshold = projectData?.silenceThreshold; // Time in ms to detect silence
      const silenceDetectionThreshold = projectData?.silenceDetectionThreshold; // Threshold for detecting speaking
      const loudnessThreshold = projectData?.loudnessThreshold; // Threshold for detecting loudness (adjust as needed)
      const mimeType = "audio/webm";

      let audioRecorder;
      let isRecording = false;
      let audioContext;
      let stream;

      if (!audioPermission) {
        // Request audio permission and stream
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();

        // Connect source to analyser
        source.connect(analyser);

        // Configure analyser
        analyser.fftSize = 2048;
        const bufferLength = analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        // set analyser data
        setRecordingAnalyzer({ analyser, bufferLength, dataArray });

        // Function to check for audio activity
        const checkAudioActivity = () => {
          // Keep checking for activity
          animationFrameIdRef.current = requestAnimationFrame(checkAudioActivity);

          // Skip processing if paused, if the bot is speaking, or if we're ignoring audio input
          if (isProcessingPaused || isPlaying || ignoreAudioInput.current) return;

          analyser.getByteTimeDomainData(dataArray);

          const isSpeaking = dataArray.some((value) => Math.abs(value - 128) > silenceDetectionThreshold);
          if (isSpeaking) {
            SpeechRecognition.startListening({
              continuous: browserSupportsContinuousListening,
            });
            onAudioActivity();
            if (!isRecording && isVoiceLoud() && !isPaused.current && !isPlaying && !ignoreAudioInput.current) {
              startRecording(stream);
            }
          }
        };

        // Function to handle audio activity
        const onAudioActivity = () => {
          clearTimeout(silenceTimer);
          silenceTimer = setTimeout(() => {
            if (isRecording && !isPlaying) {
              stopRecording();
            }
          }, silenceThreshold);
        };

        // Function to determine if the voice is loud
        const isVoiceLoud = () => {
          let sumSquares = 0;
          for (let i = 0; i < bufferLength; i++) {
            const value = dataArray[i] - 128;
            sumSquares += value * value;
          }
          const rms = Math.sqrt(sumSquares / bufferLength);

          return rms > loudnessThreshold;
        };

        // Function to start recording
        const startRecording = (stream) => {
          if (isPlaying || ignoreAudioInput.current) return;
          try {
            audioRecorder = new MediaRecorder(stream, { mimeType });
            audioRecorder.start();
            setAudioRecorder(audioRecorder);
            isRecording = true;
            setVoiceRecording(true);
          } catch (err) {}
        };

        // Function to stop recording
        const stopRecording = () => {
          if (audioRecorder && audioRecorder.state !== "inactive") {
            try {
              audioRecorder.stop();
            } catch (err) {}
          }
          SpeechRecognition.stopListening();
          resetTranscript();
          setVoiceRecording(false);
          isRecording = false;
        };

        // Start checking for audio activity
        checkAudioActivity();

        // Set state or perform further actions
        setaudioPermission(true);

        // Set cleanup function
        setCleanupFunction(() => () => {
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
          }
          if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
          }
          if (silenceTimer) {
            clearTimeout(silenceTimer);
          }
          if (audioContext && audioContext.state !== "closed") {
            try {
              audioContext.close();
            } catch (err) {}
          }
        });
      }
    } catch (error) {
      toastAlert("Please provide access to your microphone", ALERT_TYPES.ERROR);
    }
  };

  const uploadAudioHandler = (event) => {
    if (typeof event.data === "undefined") return;
    setLoading(true);
    convertWebMToWAV(event.data)
      .then((wavBlob) => {
        convertBlobToBase64(wavBlob).then((base64) => {
          sendAudioHelper(base64);
        });
      })
      .catch((eror) => {
        setLoading(false);
      });
  };

  const togglePause = () => {
    setLoading(false);
    if (isPaused.current) {
      if (audioRecorder && audioRecorder.state === "paused") {
        audioRecorder.resume();
      }
      if (audioRecorder && audioRecorder.state === "inactive") {
        audioRecorder.start();
      }
    } else {
      if (audioRecorder && audioRecorder.state === "recording") {
        audioRecorder.pause();
      }
    }
    isPaused.current = !isPaused.current;
    setIsProcessingPaused(!isProcessingPaused);
  };

  const inputPreviewHandler = () => {
    const shouldStart = !inputPreview;
    setLoading(false);
    setInputPreview(shouldStart);
    if (audioRecorder) {
      if (shouldStart) {
        if (audioRecorder.state === "paused") {
          audioRecorder.resume();
        }
      } else if (audioRecorder.state === "recording") {
        audioRecorder.pause();
      }
    }
    setIsProcessingPaused(shouldStart);
    isPaused.current = shouldStart;
  };

  const resetSessionHandler = async () => {
    setResettingSession(true);

    // Reset UI states
    resetTranscript();
    setMessage("");
    setSubTitles("");
    setLoading(false);
    setPlaying(false);
    setStarterPreview(true);

    // Stop any ongoing audio
    if (audioRecorder && audioRecorder.state !== "inactive") {
      try {
        audioRecorder.stop();
      } catch (err) {}
    }
    SpeechRecognition.stopListening();
    await anamClientRef.current.stopStreaming();

    // Clean up and reinitialize audio context and stream
    await initializeAnamClient();
    if (cleanupFunction) {
      try {
        cleanupFunction();
      } catch (err) {}
      setCleanupFunction(null);
    }

    // Reset audio related states to force recreation
    setAudioRecorder(null);
    setRecordingAnalyzer(null);
    setaudioPermission(false);
    setVoiceRecording(false);

    // Reconnect the websocket
    if (typeof reconnect === "function") {
      try {
        reconnect();
      } catch (err) {}
    }

    // Set up media stream again
    try {
      setupMediaStream();
    } catch (err) {}
    setResettingSession(false);
  };

  // HOOKS
  useEffect(() => {
    if (!projectData) return;
    async function setup() {
      if (!audioPermission && !cleanupFunction) await setupMediaStream();
    }
    setup();
    return () => {
      if (cleanupFunction) {
        cleanupFunction();
      }
    };
  }, [audioPermission, cleanupFunction, projectData]);

  useEffect(() => {
    if (audioRecorder) audioRecorder.addEventListener("dataavailable", uploadAudioHandler);

    return () => {
      if (audioRecorder) audioRecorder.removeEventListener("dataavailable", uploadAudioHandler);
    };
  }, [audioRecorder]);

  useEffect(() => {
    if (recordingAnalyzer === null) return;
    if (audioRecorder?.state === "inactive") return;

    const { analyser, dataArray } = recordingAnalyzer;

    const updateBars = () => {
      analyser.getByteFrequencyData(dataArray);
      const sum = dataArray.reduce((a, b) => a + b, 0);
      const avgFrequency = sum / dataArray.length;
      const opacity = Math.min(avgFrequency / 80, 1);
      recordingBarsRef.current.forEach((bar, i) => {
        if (bar) {
          const barHeight = (dataArray[i] / 400) * 100; // Convert to percentage
          bar.style.height = `${barHeight}%`;
        }
      });
      if (audioLoaderRef.current) audioLoaderRef.current.style.opacity = opacity;
      requestAnimationFrame(updateBars);
    };

    updateBars();
  }, [recordingAnalyzer]);

  useEffect(() => {
    if (!anamClientRef?.current) return;

    const handleStreamEvent = (event) => {
      if (event.content.includes("<EOS>")) {
        onAudioEndHandler();
      }
      else if (!ignoreAudioInput.current) {
        ignoreAudioInput.current = true;
        if (audioRecorder && audioRecorder.state === "recording") {
          try {
            audioRecorder.stop();
            SpeechRecognition.stopListening();
            setVoiceRecording(false);
          } catch (err) {}
        }
      }
    };

    anamClientRef.current.addListener(AnamEvent.MESSAGE_STREAM_EVENT_RECEIVED, handleStreamEvent);

    return () => {
      if (anamClientRef.current) {
        anamClientRef.current.removeListener(AnamEvent.MESSAGE_STREAM_EVENT_RECEIVED, handleStreamEvent);
      }
    };
  }, [anamClientRef, isPlaying, audioRecorder]);

  useEffect(() => {
    if (voiceRecording && transcript) {
      setSubTitles(transcript);
    }
  }, [transcript, voiceRecording]);

  useEffect(() => {
    if (readyState === 1) setinitLoad(false);
  }, [readyState]);

  useEffect(() => {
    if (isPlaying) {
      ignoreAudioInput.current = true;
      SpeechRecognition.stopListening();
      if (audioRecorder && audioRecorder.state === "recording") {
        try {
          audioRecorder.stop();
          setVoiceRecording(false);
        } catch (err) {}
      }
    }
  }, [isPlaying]);

  if (readyState === 0 && initLoad) {
    return <Loader />;
  }

  return (
    <>
      {projectData.enableStarters && (
        <button className="suggestions-toggle" onClick={() => setStarterPreview(!starterPreview)}>
          <img draggable="false" src={Images.SuggestionThumb} alt="ai widget" />
        </button>
      )}

      {/* Add New Session button */}
      <button className="reset-session-button" onClick={resetSessionHandler} disabled={resettingSession}>
        <FontAwesomeIcon spin={resettingSession} icon={resettingSession ? faSpinner : faArrowsRotate} />
        <span>New Session</span>
      </button>

      <div className="top-box">
        {subTitles && (
          <div className="subtitles-box">
            <p>{subTitles.replaceAll("<EOS>", "")}</p>
          </div>
        )}
        {projectData.enableStarters && starterPreview && (
          <div className="suggestions-box">
            <h2>
              <img src={Images.SuggestionIcon} alt="" /> Suggestions
            </h2>
            <div className="suggestions-list">
              {projectData?.starterGroups?.map((group, index) => (
                <React.Fragment key={index}>
                  {group?.starters?.map((item, i) => (
                    <button key={i} onClick={() => sendMessageHelper(item)}>
                      {item}
                    </button>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="bottom-box">
        <div className={clsx("input-box customize-form", inputPreview && "active")}>
          <button className="toggle-button" onClick={inputPreviewHandler}>
            <FontAwesomeIcon icon={inputPreview ? faTimes : faKeyboard} />
          </button>
          <Input
            value={message}
            placeholder="Type your prompt"
            onChange={(e) => setMessage(e.target.value)}
            onPressEnter={() => sendMessageHelper()}
          />
          <Button invertedTheme className="search-button" disabled={disabledInput} onClick={() => sendMessageHelper()}>
            <FontAwesomeIcon spin={isLoading} icon={isLoading ? faSpinner : faArrowUp} />
          </Button>
        </div>
        {!inputPreview && (
          <>
            {!isPaused.current && (
              <div className={clsx("listening-box", isUserSpeaking && "active")}>
                <FontAwesomeIcon icon={faMicrophoneAlt} />
                {!!isUserSpeaking && (
                  <div className="frequency">
                    {Array.from({ length: 5 }).map((_, i) => (
                      <div key={i} className="bar" ref={(el) => (recordingBarsRef.current[i] = el)} />
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className="action-box">
              <button className="pause" onClick={togglePause}>
                <FontAwesomeIcon icon={isPaused.current ? faPlay : faPause} />
              </button>
              <button className="cancel" onClick={closeHandler}>
                <FontAwesomeIcon icon={faStop} />
              </button>
            </div>
          </>
        )}
        <p className="tagline">{isProjectApp ? projectData?.desclaimerMessage ?? discalimerMessage : discalimerMessage}</p>
      </div>
    </>
  );
};

export default ChatBox;
