import useWebSocket from "react-use-websocket";
import { useEffect, useCallback } from "react";
import {
  AVATAR_MODE_MODALITIES,
  AVATAR_SYSTEM_PROMPT,
} from "../../../../../constants";

const MODEL = "gpt-4o-mini-realtime-preview-2024-12-17";
const VOICE = "alloy";
const API_URL = `wss://api.openai.com/v1/realtime?model=${MODEL}`;

export function useRealtimeSession(apiKey, systemPrompt, onTranscriptUpdate) {
  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    API_URL,
    {
      protocols: [
        "realtime",
        `openai-insecure-api-key.${apiKey}`,
        "openai-beta.realtime-v1",
      ],
      onOpen: () => {
        updateSession();
      },
      onMessage: (message) => {
        processMessages(JSON.parse(message.data));
      },
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    if (readyState === 1) {
      updateSession();
    }
  }, [readyState]);

  const reconnect = useCallback(() => {
    const ws = getWebSocket();
    if (ws) {
      ws.close();
    }
  }, [getWebSocket]);

  function updateSession() {
    sendJsonMessage({
      type: "session.update",
      session: {
        modalities: AVATAR_MODE_MODALITIES,
        instructions: systemPrompt ?? AVATAR_SYSTEM_PROMPT,
        voice: VOICE,
        input_audio_format: "pcm16",
        input_audio_transcription: { model: "whisper-1" },
        temperature: 0.8,
        max_response_output_tokens: "inf",
        turn_detection: null,
      },
    });
  }

  function processMessages(data) {
    const messageType = data.type;

    switch (messageType) {
      case "response.text.delta":
        if (data.delta && onTranscriptUpdate) {
          onTranscriptUpdate(data.delta);
        }
        break;
      case "response.text.done":
        if (onTranscriptUpdate) {
          onTranscriptUpdate("<EOS>");
        }
        break;
      default:
        break;
    }
  }

  return { sendJsonMessage, lastJsonMessage, readyState, reconnect };
}
