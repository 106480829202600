import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { unsafe_createClientWithApiKey } from "@anam-ai/js-sdk";
import { AnamEvent } from "@anam-ai/js-sdk/dist/module/types";
import { Loader } from "../../../../../components";
import PreviewBox from "./preview-box";
import ChatBox from "./chat-box";
import "./styles.scss";
import Header from "../header";

const AvatarBox = ({ previewHandler, isProjectApp, appConfig }) => {
  // STATES
  const [audioPermission, setAudioPermission] = useState(false);
  const [isAvatarLoading, setAvatarLoading] = useState(true);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.projectData);

  // CONST VALS
  const anamClientRef = useRef(null);
  const anamStreamRef = useRef(null);

  // HELPERS
  const initializeAnamClient = async () => {
    anamClientRef.current = await unsafe_createClientWithApiKey(projectData?.avatarApiKey, {
      personaId: "3a20ad66-10ca-4452-92d7-e46374738c61",
      disableBrains: true,
    });
    await anamClientRef.current.muteInputAudio();

    await anamClientRef.current.streamToVideoAndAudioElements("avatar-video-element", "avatar-audio-element");

    anamClientRef.current.addListener(AnamEvent.VIDEO_PLAY_STARTED, () => {
      anamStreamRef.current = anamClientRef.current.createTalkMessageStream();
      setAvatarLoading(false);
    });
  };

  // HOOKS
  useEffect(() => {
    const setup = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioPermission(true);
      } catch (error) {
        setAudioPermission(false);
      }
      setAvatarLoading(true);
      await initializeAnamClient();
    };
    setup();
  }, []);

  if (audioPermission === false) {
    return <h4 className="permission-error">Please allow media permission</h4>;
  }

  return (
    <div className="avatar-chat-box">
      <Header isProjectApp audioHeader projectData={projectData} />
      <PreviewBox />
      {isAvatarLoading ? (
        <Loader />
      ) : (
        <ChatBox
          anamStreamRef={anamStreamRef}
          anamClientRef={anamClientRef}
          previewHandler={previewHandler}
          appConfig={appConfig}
          initializeAnamClient={initializeAnamClient}
          isProjectApp={isProjectApp}
        />
      )}
    </div>
  );
};

export default AvatarBox;
